import { useEffect, useRef } from "react";
import VanillaTilt from "vanilla-tilt";

export const Tilt = (props: any) => {
    const tilt = useRef<HTMLDivElement | null>(null);
  
    useEffect(() => {
        if (tilt)
        {
            VanillaTilt.init(tilt.current as HTMLDivElement, {
                max: 10,
                speed: 200,
                glare: true,
                'max-glare': 0.5,
              });
        }
    }, []);
  
    return <div ref={tilt} >{props.children}</div>;
  }