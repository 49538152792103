import { Col, Row, Typography, Image, Popover, Button } from 'antd';
import React from 'react';
import 'antd/dist/antd.css';
import './App.css';
import { MailOutlined, HomeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Tilt } from './Components/Tilt';

const { Link } = Typography;

function App() {
     
  const doEmail = () => {
    var name = "info";
    var domain = "pinpoint-solutions";
    var domainExtension = "nl";
    var result = "mailto:" + name + '@' + domain + "." + domainExtension + "?subject=Web Inquiry";
    window.location.href = result;
  }

  return (
    <React.Fragment>
      <div className='content'>
        <Row>
          <Col span={24} style={{textAlign: 'center'}}>
            <Tilt>
              <Image src="images/PP6_Logo_Transp.png" className='logo' alt='Logo' preview={false} />
            </Tilt>
          </Col>
        </Row>

        <Row style={{textAlign: 'center', marginTop: 35}}>
          <Col offset={3} span={6}>
            <Popover 
              title='Address'
              placement='bottom'
              content={
                <div>
                  Galjoen 2<br/>
                  4511 JX<br/>
                  Breskens<br/>
                  Netherlands
                </div>} 
              >
              <Link title='Address' style={{ cursor: 'help' }}>
                <HomeOutlined style={{fontSize: 24}} />
              </Link>
            </Popover>
          </Col>
          <Col span={6}>
            <Popover 
              title='Info'
              placement='bottom'
              content={
                <div>Kvk. 61514136</div>
                } >
              <Link title='Info' style={{ cursor: 'help' }}>
                <InfoCircleOutlined style={{fontSize: 24}} />
              </Link>
            </Popover>
          </Col>
          <Col span={6}>
            <Popover 
              title='Contact us'
              placement='bottom'
              content={
                <div>
                  Send us an email by clicking<br/>
                  on the button below.<br/>
                  <br/>
                  <Button type='primary' icon={<MailOutlined/>} size='middle' onClick={doEmail}>Email</Button> 
                </div>
                } >
              <Link title='Contact us' style={{ cursor: 'help' }}>
                <MailOutlined style={{fontSize: 24}} />
              </Link>
            </Popover>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default App;
